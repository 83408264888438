<template>
  <transition name="fade">
    <DynamicScroller
      v-show="subtitlesSegments.length !== 0"
      id="timeline-wrapper"
      ref="scroller"
      :items="subtitlesSegments"
      :min-item-size="112"
      class="scroller h-full p-5"
      style="transition-delay: 500ms"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.id, item.text, item.uid]"
        >
          <SubtitlesSegment
            :id="item.id"
            :key="item.id"
            :mode="'editor'"
            :subtitle="item"
            :order="index"
            :playing="activeSubtitlesSegments.findIndex((x) => x.id === item.id) >= 0"
            :merge="index === mergeSegmentOrder + 1"
            :potential="item.potential"
            @merge-hover="mergeHover"
            @merge-segment="mergeSegment"
          ></SubtitlesSegment>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </transition>
</template>

<script>
import SubtitlesSegment from '@/components/SubtitlesSegment';

export default {
  name: 'TimelineVertical',
  components: {
    SubtitlesSegment,
  },
  props: {
    presentation: Object,
  },
  data() {
    return {
      mergeSegmentOrder: undefined,
      sourceSegmentID: null,
      mergeSegmentID: null,
    };
  },
  computed: {
    subtitlesSegments() {
      return this.$store.state.subtitles;
    },
    filteredSubtitlesSegments() {
      return this.$store.getters.filteredSubtitlesSegments;
    },
    activeSubtitlesSegments() {
      return this.$store.getters.activeSubtitlesSegments;
    },
    firstActiveSubtitlesSegmentId() {
      return this.$store.getters.firstActiveSubtitlesSegmentId;
    },
    currentTime() {
      return this.$store.getters.getVideo.currentTime();
    },
    autoScroll() {
      return this.$store.getters.settings.autoScroll;
    },
  },
  watch: {
    firstActiveSubtitlesSegmentId: {
      handler(firstActiveSubtitlesSegmentId) {
        if (this.autoScroll && firstActiveSubtitlesSegmentId) {
          this.$nextTick(() => this.scrollToSegment(firstActiveSubtitlesSegmentId));
        }
      },
    },
  },
  methods: {
    addSegmentToCurrentTime() {
      this.$store.dispatch('addSegmentToCurrentTime', this.currentTime);
    },
    mergeHover(mergeObject) {
      if (mergeObject == undefined || this.filteredSubtitlesSegments[mergeObject.order + 1] == undefined) {
        this.mergeSegmentID = null;
        this.sourceSegmentID = null;
        this.mergeSegmentOrder = undefined;
        return;
      }

      this.mergeSegmentOrder = mergeObject.order;
      this.sourceSegmentID = mergeObject.id;
      this.mergeSegmentID = this.filteredSubtitlesSegments[mergeObject.order + 1].id;
    },
    mergeSegment() {
      if (!this.sourceSegmentID || !this.mergeSegmentID) return;
      this.$store
        .dispatch('mergeSegments', {
          source: this.sourceSegmentID,
          merge: this.mergeSegmentID,
        })
        .then(() => {
          this.mergeSegmentOrder = undefined;
        });
    },
  },
};
</script>
