<template>
  <div v-shortkey="['esc']" class="fixed inset-0 z-20 overflow-y-auto" @shortkey="close">
    <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>&#8203;
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">
                {{ $t('warnings') }}
              </h3>
              <hr class="mb-4 mt-2" />
              <div class="text-sm">
                <div v-for="(warning, index) in warnings" :key="index" class="mb-2 cursor-pointer hover:text-gray-700">
                  <div
                    v-for="w in warning"
                    :key="w.uid"
                    class="cursor-pointer hover:text-gray-700"
                    @click="showWarning(w.uid)"
                  >
                    <span v-if="w.type === 'overlap'">{{ $t('overlap') }}</span>
                    <span v-else-if="w.type === 'tooShort'">{{ $t('tooShort') }}</span>
                    <span class="rounded mx-1 inline items-center bg-red-300 text-xs font-semibold text-red-900">
                      {{ '#' + subtitlesSegments.findIndex((segment) => segment.uid === warning[0].uid) }}
                    </span>
                    <span class="invisible">.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              v-shortkey="['esc']"
              type="button"
              class="border focus:outline-none transition inline-flex w-full justify-center rounded-md border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @shortkey="close"
              @click="close"
            >
              {{ $t('close') }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"warnings": "Warnings",
"close": "Close",
"overlap": "Overlaping segments",
"tooShort": "Segment is too short"
},
"cs": {
"warnings": "Varování",
"close": "Zavřít",
"overlap": "Překrývající se segmenty",
"tooShort": "Segment je příliš krátký"
}
}

</i18n>

<script>
export default {
  name: 'WarningsModal',
  computed: {
    warnings() {
      return this.$store.getters.getWarnings;
    },
  },
  watch: {
    subtitlesSegments() {
      return this.$store.getters.subtitlesSegments;
    },
    warnings: function () {
      return this.$store.getters.getWarnings;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('showWarnings', false);
    },
    showWarning(uid) {
      let segment = this.subtitlesSegments.find((segment) => segment.uid == uid);
      this.scrollToSegment(segment.id);
      this.$store.dispatch('showWarnings', false);
    },
  },
};
</script>

<style scoped></style>
