<template>
  <div>
    <template
      v-if="
        editorState === 'loading-presentation' ||
        editorState === 'select-subtitles' ||
        editorState === 'loading-subtitles'
      "
    >
      <LoadingView
        :editor-state="editorState"
        :loading-errors="loadingErrors"
        @subtitles-selected="loadPresentationSubtitles"
      />
    </template>
    <template v-if="editorState === 'edit-subtitles'">
      <Editor />
    </template>

    <transition name="fade">
      <Modal v-if="$store.state.showModal"></Modal>
    </transition>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';

import Editor from './Editor.vue';
import LoadingView from './LoadingView.vue';

export default {
  name: 'MainView',
  components: {
    Editor,
    LoadingView,
    Modal,
  },
  data() {
    return {
      editorState: 'none',
      loadingErrors: [],
    };
  },
  computed: {
    presentationId() {
      return this.$store.state.presentationId;
    },

    availablePresentationSubtitles() {
      return this.$store.state.editorData.available_subtitles;
    },
  },
  async mounted() {
    if (this.editorState === 'none') {
      this.loadPresentation();
    }
  },
  methods: {
    async loadPresentation() {
      this.loadingErrors = [];
      this.editorState = 'loading-presentation';

      const result = await this.$store.dispatch('loadEditorData', {
        presentationId: this.$route.params.id,
        subtitlesEditorToken: this.$route.query.token,
      });

      if (result.errors) {
        this.loadingErrors = result.errors;
        return;
      }

      this.editorState = 'select-subtitles';

      if (this.availablePresentationSubtitles && this.availablePresentationSubtitles.length === 1) {
        this.loadPresentationSubtitles(this.availablePresentationSubtitles[0].id);
      }
    },

    async loadPresentationSubtitles(subtitlesId) {
      this.loadingErrors = [];
      this.editorState = 'loading-subtitles';

      const result = await this.$store.dispatch('loadPresentationSubtitles', {
        presentationSubtitlesId: subtitlesId,
      });

      if (result.errors) {
        this.loadingErrors = result.errors;
        return;
      }

      this.editorState = 'edit-subtitles';
    },
  },
};
</script>
