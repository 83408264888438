<template>
  <div v-shortkey="['esc']" class="fixed inset-0 z-20 overflow-y-auto" @shortkey="cancel">
    <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>&#8203;
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <!-- Heroicon name: exclamation -->
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">Save changes</h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                  Please confirm that you want to save the changes. Keep in mind there is a processing time and it might
                  take couple of minutes before your changes are reflected.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              v-shortkey="['enter']"
              type="button"
              class="border focus:outline-none transition inline-flex w-full justify-center rounded-md border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @shortkey="save"
              @click="save"
            >
              Save
            </button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="border focus:outline-none transition inline-flex w-full justify-center rounded-md border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
              @click="cancel"
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringifySync } from 'subtitle';

export default {
  name: 'SaveModal',
  props: {
    presentation: Object,
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    presentationId() {
      return this.$store.state.presentationId;
    },
    subtitlesEditorToken() {
      return this.$store.state.subtitlesEditorToken;
    },
    presentationSubtitlesId() {
      return this.$store.state.presentationSubtitlesId;
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch('setSaveModal', false);
    },
    async save() {
      this.$store.dispatch('setSaveModal', false);
      if (!this.presentation || !this.presentation.presentation_id) return;

      const subtitles = stringifySync(
        [
          {
            type: 'header',
            data: 'WEBVTT',
          },
          ...this.$store.getters.subtitlesSegments.map((el) => {
            return {
              type: 'cue',
              data: {
                start: el.start,
                end: el.end,
                text: el.text,
              },
            };
          }),
        ],
        { format: 'WebVTT' },
      );

      const data = JSON.stringify({
        subtitles_editor_token: this.subtitlesEditorToken,
        presentation_id: this.presentationId,
        subtitles_id: this.presentationSubtitlesId,
        webvtt: subtitles,
      });

      const response = await fetch('https://studio.slideslive.com/api/presentations/v1/subtitles_editor', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: data,
      }).then((response) => response.json());

      if (response && response.success) {
        this.$store.dispatch('resetUnsaved');
        this.$store.dispatch('resetEditing');
        this.$store.dispatch('deleteSubtitlesDataFromLocalStorage');

        this.showSavedSuccessfullyModal();
      } else if (response.errors) {
        this.showSavingFailedModal({ errors: response.errors });
      } else {
        this.showSavingFailedModal();
      }
    },
    showSavedSuccessfullyModal() {
      this.$store.dispatch('setModalData', {
        title: 'Saved successfully',
        text: 'Subtitles were successfully saved. They are now being processed and they will be live in couple of minutes.',
        type: 'success',
        callback: () => {
          window.location.reload();
        },
        buttonOk: {
          display: true,
          text: 'OK',
        },
      });
      this.$store.dispatch('displayModal', true);
    },
    showSavingFailedModal({ errors } = {}) {
      if (!errors || !errors.length) {
        errors = ['Unexpected error occured. Try again later or contact SlidesLive support.'];
      }

      this.$store.dispatch('setModalData', {
        title: 'Saving failed',
        text: errors.join(' '),
        type: 'error',
        buttonOk: {
          display: true,
          text: 'OK',
        },
      });
      this.$store.dispatch('displayModal', true);
    },
    dialogClosed(exitType) {
      if (exitType == 'custom') {
        // update settings savedInfoDialog
        this.$store.dispatch('setSettingsPreference', {
          key: 'savedInfoDialog',
          value: false,
        });
      }
    },
  },
};
</script>

<style scoped></style>
