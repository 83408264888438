<template>
  <div class="w-full">
    <div
      class="border transition-duration-400 rounded-lg border-gray-300 bg-gray-200 p-4 text-center text-lg font-medium text-gray-800 shadow-sm transition-all"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubtitlesStaticSegment',
  props: {
    subtitle: Object,
    id: Number,
  },
  data() {
    return {
      end: '',
      start: '',
      text: '',
    };
  },
  created() {
    this.end = this.convertTime(this.subtitle.end);
    this.start = this.convertTime(this.subtitle.start);
    this.text = this.subtitle.text;
  },
  methods: {
    convertTime(ms) {
      let milliseconds = parseInt(ms % 1000),
        seconds = Math.floor((ms / 1000) % 60),
        minutes = Math.floor((ms / (1000 * 60)) % 60),
        hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds.toString().padStart(3, '0');
    },
  },
};
</script>

<style scoped>
.disabled-textarea {
  background-color: rgba(239, 239, 239, 0.3);
}
</style>
