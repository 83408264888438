<template>
  <div :data-subtitle-id="subtitle.id" :data-subtitle-uid="subtitle.uid" :data-scroller-index="order">
    <div
      class="relative flex flex-col justify-center bg-transparent"
      :style="
        mergeSegmentOrder === order || merge
          ? 'transition: margin 250ms, 0ms border-radius 0ms, background-color 150ms, padding 250ms'
          : 'transition: margin 250ms, 0ms border-radius 500ms, background-color 150ms, padding 250ms'
      "
      :class="[
        merge ? 'mt-0-i rounded-b-lg bg-red-400 px-5 pb-5 pt-3' : mergeSegmentOrder === order ? '' : 'rounded-b-lg',
        mergeSegmentOrder === order ? 'rounded-t-lg bg-red-400' : merge ? '' : 'rounded-t-lg',
        order === 0 ? 'pt-0' : segmentSelected ? 'mt-5' : 'pt-5',
      ]"
      @click="setSelectedSubtitlesSegment"
    >
      <div
        :class="[
          segmentSelected || merge ? 'border-2 border-red-400 bg-red-200' : merge ? '' : 'border',
          potential ? 'border border-red-400 bg-red-200' : potential ? '' : '',
          'rounded-lg transition-all duration-150',
          playing ? 'border-red-300 bg-red-200' : 'bg-gray-100',
        ]"
      >
        <div :id="'subtitle-' + id" class="flex flex-row space-x-3 px-2 py-2">
          <div class="flex flex-col space-y-3">
            <div
              v-shortkey="['ctrl', 'space']"
              class="flex flex-row items-center space-x-1"
              @shortkey="segmentSelected ? seekToTime() : ''"
            >
              <span
                v-show="segmentSelected"
                class="flex cursor-pointer items-start rounded-full text-gray-500 transition-colors duration-150 hover:text-gray-700"
                @click="seekToTime"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <input
                v-model="start"
                class="border focus:outline-none inline-flex rounded-md border-gray-300 px-2 py-1 text-sm shadow-sm transition-all duration-150 ease-in-out focus:border-red-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
                :class="[{ 'border-red-500 bg-red-200': startTimeError }, !merge ? 'opacity-1' : 'opacity-0']"
                @focus="setSelectedSubtitlesSegment"
                @blur="
                  checkTime();
                  checkForWarnings();
                "
                @keyup="
                  updateSegment();
                  checkForWarnings();
                "
              />
            </div>
            <div class="flex flex-row items-center space-x-1">
              <span v-show="segmentSelected" class="h-5 w-5"></span>
              <input
                v-model="end"
                class="border focus:outline-none inline-flex rounded-md border-gray-300 px-2 py-1 text-sm shadow-sm transition-all duration-150 ease-in-out focus:border-red-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
                :class="[
                  { 'border-red-500 bg-red-200': endTimeError },
                  mergeSegmentOrder === order ? 'opacity-0' : 'opacity-1',
                ]"
                @focus="setSelectedSubtitlesSegment"
                @blur="
                  checkTime();
                  checkForWarnings();
                "
                @keyup="
                  updateSegment();
                  checkForWarnings();
                "
              />
            </div>
          </div>

          <div
            v-shortkey="['ctrl', 'del']"
            class="flex flex-1 flex-row items-center space-x-2"
            @shortkey="segmentSelected ? removeSegment() : ''"
          >
            <textarea
              v-model="text"
              rows="3"
              class="transition focus:outline-none form-textarea block h-full w-full shadow-sm duration-150 ease-in-out focus:border-red-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @focus="setSelectedSubtitlesSegment"
              @blur="
                checkTime();
                checkForWarnings();
              "
              @keyup="
                updateSegment();
                checkForWarnings();
              "
            ></textarea>

            <div class="flex flex-col space-y-2">
              <transition name="fade">
                <div v-if="warning" class="tooltip-wrapper">
                  <span class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-orange-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>

                  <Tooltip
                    style="right: 100%; top: 50%; transform: translateY(-50%)"
                    class="whitespace-no-wrap mr-1"
                    :text="
                      warnings[subtitle.uid] != undefined
                        ? $t('controls.' + warnings[subtitle.uid][0].type)
                        : $t('controls.warning')
                    "
                    color="white"
                    background="gray-800"
                  ></Tooltip>
                </div>
              </transition>

              <transition name="fade">
                <div v-if="!subtitle.saved" class="tooltip-wrapper">
                  <span class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                      />
                    </svg>
                  </span>

                  <!--
                      <Tooltip style="right: 100%; top: 50%; transform: translateY(-50%);" class="mr-1 whitespace-no-wrap" :text="$t('controls.unsavedChanges')" color="white" background="gray-800"></Tooltip>
                  // Zakomentoval Jakub M. - tooltip má opacity-0 a blokuje tedy text input pod ním. Viz screenshot na slacku.
                  -->
                </div>
              </transition>

              <span
                v-show="segmentSelected"
                class="flex-0 flex cursor-pointer items-start rounded-full text-gray-500 transition-colors duration-150 hover:text-gray-700"
                @click="removeSegment()"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade-height" :duration="300">
        <div v-if="segmentSelected" class="mx-5 mt-3 flex flex-row space-x-3">
          <span
            class="inline-flex flex-1 rounded-md shadow-sm"
            @click="persistPotentialSegment"
            @mouseenter="addPotentialSegment"
            @mouseleave="removePotentialSegments"
          >
            <button
              type="button"
              class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
            >
              <svg class="mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
              <span v-shortkey="['ctrl', '+']" @shortkey="segmentSelected ? addSegmentAfter() : ''">{{
                $t('controls.addSegmentBelow')
              }}</span>
            </button>
          </span>

          <span
            class="inline-flex flex-1 rounded-md shadow-sm"
            @click="$emit('merge-segment')"
            @mouseenter="
              $emit('merge-hover', { order: order, id: id });
              mergeSegmentOrder = order;
            "
            @mouseleave="
              $emit('merge-hover', undefined);
              mergeSegmentOrder = null;
            "
          >
            <button
              type="button"
              class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1 h-4 w-4"
                fill="currentColor"
                x="0px"
                y="0px"
                viewBox="5 -5 150 165.5"
                xml:space="preserve"
              >
                <g>
                  <path
                    class="st0"
                    d="M47.6,39.2H112V16.4c0-6.5-5.3-11.7-11.7-11.7H14.2C7.8,4.7,2.5,10,2.5,16.4v86.1c0,6.5,5.3,11.7,11.7,11.7   h26.3v-68C40.5,42.4,43.7,39.2,47.6,39.2z"
                  />
                  <path
                    class="st0"
                    d="M135.8,39.2H112v68c0,3.9-3.2,7.1-7.1,7.1H40.5v20.2c0,6.5,5.3,11.7,11.7,11.7h83.5c6.5,0,11.7-5.3,11.7-11.7   V50.9C147.5,44.5,142.2,39.2,135.8,39.2z"
                  />
                  <path
                    class="st0"
                    d="M99.7,68.5H82V50.7c0-1.5-1.2-2.7-2.7-2.7h-8.5c-1.5,0-2.7,1.2-2.7,2.7v17.8H50.3c-1.5,0-2.7,1.2-2.7,2.7v8.5   c0,1.5,1.2,2.7,2.7,2.7H68v17.8c0,1.5,1.2,2.7,2.7,2.7h8.5c1.5,0,2.7-1.2,2.7-2.7V82.4h17.8c1.5,0,2.7-1.2,2.7-2.7v-8.5   C102.4,69.7,101.2,68.5,99.7,68.5z"
                  />
                </g>
              </svg>
              <span>{{ $t('controls.mergeSegmentBelow') }}</span>
            </button>
          </span>
        </div>
      </transition>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "controls": {
      "addSegmentBelow": "Add new segment below",
      "mergeSegmentBelow": "Merge with segment below",
      "unsavedChanges": "Unsaved changes",
      "warning": "Warning",
      "overlap": "Overlaping segment",
      "tooShort": "Segment is too short"
    },
    "warnings": {
      "segmentTooShort": {
        "title": "Segment time length too short",
        "text": "Segment time length is too short in order to read properly. It is set that each character requires at least."
      },
      "segmentOverlapping": {
        "title": "Segments are overlapping",
        "text": "Some segments are overlapping each other."
      },
      "doNotShowAgain": "Do not show again",
      "ignore": "Ignore",
      "updateLength": "Update length"
    }
  },
  "cs": {
    "controls": {
      "addSegmentBelow": "Přidat nový segment níže",
      "mergeSegmentBelow": "Sloučit se segmentem níže",
      "unsavedChanges": "Neuložené změny",
      "warning": "Varování",
      "overlap": "Překrývající segment",
      "tooShort": "Segment je příliš krátký"
    },
    "warnings": {
      "segmentTooShort": {
        "title": "Časová délka segmentu je příliš krátká",
        "text": "Časová délka segmentu je krátká na to, aby segment byl přečten včas. Dle nastavení, každé písmeno vyžaduje k přečtení."
      },
      "segmentOverlapping": {
        "title": "Segmenty se překrývají",
        "text": "Některé segmenty se překrývají."
      },
      "doNotShowAgain": "Nezobrazovat znovu",
      "ignore": "Ignorovat",
      "updateLength": "Nastavit správnou délku"
    }
  }
}
</i18n>

<script>
export default {
  name: 'SubtitlesSegment',
  props: {
    subtitle: Object,
    id: Number,
    order: Number,
    playing: Boolean,
    mode: String,
    merge: Boolean,
    potential: Boolean,
  },
  data() {
    return {
      end: '',
      start: '',
      text: '',
      endTimeError: false,
      startTimeError: false,
      mergeSegmentOrder: null,
      observer: null,
      warning: false,
    };
  },
  computed: {
    segmentSelected() {
      return this.$store.getters.selectedSubtitlesSegmentId === this.subtitle.id;
    },
    videoPlayer() {
      return this.$store.getters.getVideo;
    },
    settings() {
      return this.$store.getters.settings;
    },
    warnings() {
      return (
        this.$store.getters.getWarnings[this.subtitle.uid] &&
        this.$store.getters.getWarnings[this.subtitle.uid].length > 0
      );
    },
  },

  created() {
    this.end = this.convertTime(this.subtitle.end);
    this.start = this.convertTime(this.subtitle.start);
    this.text = this.subtitle.text;
  },

  methods: {
    setSelectedSubtitlesSegment() {
      this.$store.dispatch('setSelectedSubtitlesSegmentId', { id: this.subtitle.id });
    },

    convertTime(ms) {
      let milliseconds = parseInt(ms % 1000),
        seconds = Math.floor((ms / 1000) % 60),
        minutes = Math.floor((ms / (1000 * 60)) % 60),
        hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds.toString().padStart(3, '0');
    },

    parseTime(text) {
      let t = text.split(':');
      let ms = 0;
      ms += parseInt(t[0]) * 60 * 60 * 1000 * (t[0].length == 1 ? 10 : 1); // hours
      ms += parseInt(t[1]) * 60 * 1000 * (t[1].length == 1 ? 10 : 1); // minutes

      if (t[2].includes('.')) {
        let seconds = t[2].split('.');
        ms += parseInt(seconds[0]) * 1000 * (seconds[0].length == 1 ? 10 : 1); // seconds
        if (seconds[1].length == 1) {
          ms += parseInt(seconds[1]) * 100; // ms
        } else if (seconds[1].length == 2) {
          ms += parseInt(seconds[1]) * 10; // ms
        } else {
          ms += parseInt(seconds[1]); // ms
        }
      } else {
        ms += parseInt(t[2]) * 1000;
      }

      return ms;
    },

    validateTime(text) {
      let t = text.split(':');
      let numberRegex = /^\d+(\.\d+)?$/;
      if (t.length !== 3) {
        return false;
      }
      if (!numberRegex.test(t[0]) || !numberRegex.test(t[1]) || !numberRegex.test(t[2])) {
        return false;
      }
      return true;
    },

    addPotentialSegment() {
      this.$store.dispatch('createPotentialSegment', { order: this.order });
    },

    removePotentialSegments() {
      this.$store.dispatch('removePotentialSegments');
    },

    persistPotentialSegment() {
      this.$store.dispatch('savePotentialSegment', this.order);
    },

    removeSegment() {
      this.$store.dispatch('removeSegment', this.subtitle.id);
    },

    updateSegment() {
      this.endTimeError = false;
      this.startTimeError = false;

      if (!this.validateTime(this.end)) {
        this.endTimeError = true;
        return;
      }

      if (!this.validateTime(this.start)) {
        this.startTimeError = true;
        return;
      }

      if (this.parseTime(this.start) > this.parseTime(this.end)) {
        this.endTimeError = true;
        this.startTimeError = true;
        return;
      }

      this.$store.dispatch('updateSegment', {
        id: this.subtitle.id,
        end: this.parseTime(this.end),
        start: this.parseTime(this.start),
        text: this.text,
        uid: this.subtitle.uid,
      });
    },

    checkTime() {
      if (this.startTimeError || this.endTimeError) return;

      let end = this.parseTime(this.end);
      let start = this.parseTime(this.start);

      if (
        this.settings.subtitlesMinimumLengthPerCharacter > 0 &&
        this.text.length * this.settings.subtitlesMinimumLengthPerCharacter > end - start
      ) {
        this.$store.dispatch('setModalData', {
          title: this.$t('warnings.segmentTooShort.title'),
          text: `${this.$t('warnings.segmentTooShort.text')} ${this.settings.subtitlesMinimumLengthPerCharacter} ms.`,
          type: 'error',
          callback: this.dialogNotEnoughTimeClosed,
          buttonOk: {
            display: true,
            text: this.$t('warnings.updateLength'),
          },
          buttonCustom: {
            display: true,
            text: this.$t('warnings.doNotShowAgain'),
          },
          buttonCancel: {
            display: true,
            text: this.$t('warnings.ignore'),
          },
        });
        this.$store.dispatch('displayModal', true);
      }

      if (this.isOverlaping()) {
        if (this.settings.subtitlesOverlap) {
          this.$store.dispatch('setModalData', {
            title: this.$t('warnings.segmentOverlapping.title'),
            text: this.$t('warnings.segmentOverlapping.text'),
            type: 'error',
            callback: this.dialogOverlapingSubtitlesClosed,
            buttonOk: {
              display: true,
              text: 'OK',
            },
            buttonCustom: {
              display: true,
              text: this.$t('warnings.doNotShowAgain'),
            },
          });
          this.$store.dispatch('displayModal', true);
        }
      }
    },

    checkForWarnings() {
      if (this.startTimeError || this.endTimeError) return;

      let end = this.parseTime(this.end);
      let start = this.parseTime(this.start);

      if (
        this.settings.subtitlesMinimumLengthPerCharacter > 0 &&
        this.text.length * this.settings.subtitlesMinimumLengthPerCharacter > end - start
      ) {
        this.$store.dispatch('addWarning', { uid: this.subtitle.uid, type: 'tooShort', data: {} });
      } else {
        this.$store.dispatch('resetWarningTypeFor', { uid: this.subtitle.uid, type: 'tooShort' });
      }

      if (this.isOverlaping()) {
        this.$store.dispatch('addWarning', { uid: this.subtitle.uid, type: 'overlap', data: {} });
      } else {
        this.$store.dispatch('resetWarningTypeFor', { uid: this.subtitle.uid, type: 'overlap' });
      }
    },

    isOverlaping() {
      const end = this.parseTime(this.end);
      const start = this.parseTime(this.start);

      const subtitles = this.$store.getters.subtitlesSegments;
      return (
        subtitles.findIndex(
          (x) =>
            (start < x.start && x.start < end) ||
            (start < x.end && x.end < end) ||
            (start < x.start && end > x.end) ||
            (x.start < start && x.end > end),
        ) >= 0
      );
    },
    seekToTime() {
      this.startTimeError = false;

      if (!this.validateTime(this.start)) {
        this.startTimeError = true;
        return;
      }

      this.videoPlayer.seekTo(this.parseTime(this.start));

      if (this.videoPlayer.isPaused()) {
        this.videoPlayer.play();
      }
    },

    dialogNotEnoughTimeClosed(exitType) {
      if (exitType == 'ok') {
        this.end = this.convertTime(
          this.parseTime(this.start) + this.text.length * this.settings.subtitlesMinimumLengthPerCharacter,
        );
        this.$store.dispatch('updateSegment', {
          id: this.subtitle.id,
          end: this.parseTime(this.start) + this.text.length * this.settings.subtitlesMinimumLengthPerCharacter,
          start: this.parseTime(this.start),
          text: this.text,
          uid: this.subtitle.uid,
        });
      } else if (exitType == 'custom') {
        this.$store.dispatch('setSettingsPreference', {
          key: 'subtitlesMinimumLengthPerCharacter',
          value: 0,
        });
      }
    },

    dialogOverlapingSubtitlesClosed(exitType) {
      if (exitType == 'custom') {
        this.$store.dispatch('setSettingsPreference', {
          key: 'subtitlesOverlap',
          value: true,
        });
      }
    },
  },
};
</script>

<style scoped></style>
