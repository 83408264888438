<template>
  <div v-shortkey="['esc']" class="fixed inset-0 z-10 overflow-y-auto" @shortkey="cancel">
    <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>&#8203;
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="">
            <div class="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">
                {{ $t('additionalSettings.additionalSettings') }}
              </h3>
              <div class="mt-2 w-full">
                <hr />
                <div class="m-2 mt-4 text-sm leading-5 text-gray-500">
                  <div class="mt-2">
                    <label class="block text-sm font-medium leading-5 text-gray-700">{{
                      $t('additionalSettings.dialogOverlap')
                    }}</label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <div class="relative inline-block w-full">
                        <select
                          v-model="overlapDialog"
                          class="border focus:outline-none block w-full appearance-none rounded-md border-gray-300 bg-white px-4 py-2 pr-8 leading-tight hover:border-gray-400 focus:shadow-outline focus:shadow-outline-gray"
                        >
                          <option value="true">{{ $t('additionalSettings.yes') }}</option>
                          <option value="false">{{ $t('additionalSettings.no') }}</option>
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                        >
                          <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="block text-sm font-medium leading-5 text-gray-700">{{
                      $t('additionalSettings.dialogSave')
                    }}</label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <div class="relative inline-block w-full">
                        <select
                          v-model="savedDialog"
                          class="border focus:outline-none block w-full appearance-none rounded-md border-gray-300 bg-white px-4 py-2 pr-8 leading-tight hover:border-gray-400 focus:shadow-outline focus:shadow-outline-gray"
                        >
                          <option value="true">{{ $t('additionalSettings.yes') }}</option>
                          <option value="false">{{ $t('additionalSettings.no') }}</option>
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                        >
                          <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="block text-sm font-medium leading-5 text-gray-700">{{
                      $t('additionalSettings.autoScroll')
                    }}</label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <div class="relative inline-block w-full">
                        <select
                          v-model="autoScroll"
                          class="border focus:outline-none block w-full appearance-none rounded-md border-gray-300 bg-white px-4 py-2 pr-8 leading-tight hover:border-gray-400 focus:shadow-outline focus:shadow-outline-gray"
                        >
                          <option value="true">{{ $t('additionalSettings.yes') }}</option>
                          <option value="false">{{ $t('additionalSettings.no') }}</option>
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                        >
                          <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="block text-sm font-medium leading-5 text-gray-700">{{
                      $t('additionalSettings.minTimeLength')
                    }}</label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        v-model="minimumTime"
                        class="border focus:outline-none form-input block w-full appearance-none focus:shadow-outline sm:text-sm sm:leading-5"
                        :class="{
                          'border-red-300 hover:border-red-400 focus:border-red-400 focus:shadow-outline-red':
                            minimumTimeError,
                          'border-gray-300 hover:border-gray-400 focus:border-gray-400 focus:shadow-outline-gray':
                            !minimumTimeError,
                        }"
                        placeholder="10"
                        @keyup="minimumTimeError = false"
                        @keyup.enter="save"
                      />
                    </div>
                    <p class="mt-2 text-sm text-gray-500">{{ $t('additionalSettings.timeLengthExample') }}</p>
                  </div>
                  <div class="mt-4">
                    <label class="block text-sm font-medium leading-5 text-gray-700">{{
                      $t('additionalSettings.maximumHistoryEvents')
                    }}</label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        v-model="maximumHistory"
                        class="border focus:outline-none form-input block w-full appearance-none focus:shadow-outline sm:text-sm sm:leading-5"
                        :class="{
                          'border-red-300 hover:border-red-400 focus:border-red-400 focus:shadow-outline-red':
                            maximumHistoryError,
                          'border-gray-300 hover:border-gray-400 focus:border-gray-400 focus:shadow-outline-gray':
                            !maximumHistoryError,
                        }"
                        placeholder="100"
                        @keyup="maximumHistoryError = false"
                        @keyup.enter="save"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="transition border focus:outline-none inline-flex w-full justify-center rounded-md border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @click="save"
            >
              {{ $t('additionalSettings.save') }}
            </button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="transition border focus:outline-none inline-flex w-full justify-center rounded-md border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @click="cancel"
            >
              {{ $t('additionalSettings.cancel') }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"additionalSettings": {
"additionalSettings": "Additional settings",
"yes": "Yes",
"no": "No",
"dialogOverlap": "Show dialog when subtitle segment overlaps with another segment",
"dialogSave": "Show dialog when subtitle file was saved successfully",
"autoScroll": "Automatic scroll while video is playing?",
"minTimeLength": "Minimum time length per character (in milliseconds)",
"timeLengthExample": "Example: If a segment contains 4 characters, the time length of the segment must be at least 4 * X (X = selected value) millisecond.",
"maximumHistoryEvents": "Maximum number of changes in the history",
"save": "Save",
"cancel": "Cancel"
}
},
"cs": {
"additionalSettings": {
"additionalSettings": "Nastavení",
"yes": "Ano",
"no": "Ne",
"dialogOverlap": "Upozornit, pokud se časový interval překrývá s jiným segmentem",
"dialogSave": "Potvrdit úspěšné uložení souboru",
"autoScroll": "Automatické rolování (levého) okna se segmenty při přehrávání vide",
"minTimeLength": "Minimální délka trvání titlků dle počtu písmen (v millisekundách)",
"timeLengthExample": "Příklad: Obsahuje-li segment 4 písmena, časová délka tohoto segmentu bude alespoň 4 * X (X = zvolená hodnota) milisekundy.",
"maximumHistoryEvents": "Maximální počet změn v historii",
"save": "Uložit",
"cancel": "Zrušit"
}
}
}
</i18n>

<script>
export default {
  name: 'SettingsModal',
  data() {
    return {
      overlapDialog: 'true',
      savedDialog: 'true',
      autoScroll: 'true',
      minimumTime: '10',
      minimumTimeError: false,
      maximumHistory: '100',
      maximumHistoryError: false,
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
  },
  mounted() {
    this.overlapDialog = !this.settings.subtitlesOverlap ? 'true' : 'false';
    this.savedDialog = this.settings.savedInfoDialog ? 'true' : 'false';
    this.minimumTime = this.settings.subtitlesMinimumLengthPerCharacter + '';
    this.autoScroll = this.settings.autoScroll ? 'true' : 'false';
    this.maximumHistory = this.settings.historyMaxLimit + '';
  },
  methods: {
    cancel() {
      this.$store.dispatch('displaySettings', false);
    },
    save() {
      this.$store.dispatch('setSettingsPreference', {
        key: 'subtitlesOverlap',
        value: !(this.overlapDialog == 'true'),
      });

      this.$store.dispatch('setSettingsPreference', {
        key: 'savedInfoDialog',
        value: this.savedDialog == 'true',
      });

      this.$store.dispatch('setSettingsPreference', {
        key: 'autoScroll',
        value: this.autoScroll == 'true',
      });

      let time = parseInt(this.minimumTime);
      if (isNaN(time)) {
        this.minimumTimeError = true;
        return;
      }

      this.$store.dispatch('setSettingsPreference', {
        key: 'subtitlesMinimumLengthPerCharacter',
        value: time,
      });

      let max = parseInt(this.maximumHistory);
      if (isNaN(max)) {
        this.maximumHistoryError = true;
        return;
      }

      this.$store.dispatch('setSettingsPreference', {
        key: 'historyMaxLimit',
        value: max,
      });

      this.$store.dispatch('displaySettings', false);
    },
  },
};
</script>

<style scoped></style>
