<template>
  <div
    class="border flex flex-col space-y-2 self-start rounded-lg border-gray-400 bg-gray-300 px-4 py-2 shadow-md"
    @click="$emit('click')"
  >
    <div class="flex flex-row justify-between">
      <div class="flex items-center xl:space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden text-gray-700 xl:flex"
          style="width: 24px; height: 24px"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
          />
        </svg>
        <span class="whitespace-no-wrap text-sm font-medium leading-6 text-gray-900">{{
          subtitles.name || $t('controls.unkownLanguage')
        }}</span>
      </div>
      <span
        class="border ml-2 inline-flex items-center rounded-full bg-gray-500 px-2 py-0.5 text-xs font-medium text-white"
        >version {{ subtitles.version || 'N/A' }}</span
      >
    </div>

    <span class="text-xs font-medium text-gray-600">{{ $t('controls.lastUpdated') }} {{ formattedLastUpdatedAt }}</span>
  </div>
</template>

<i18n>
{
  "en": {
    "controls": {
      "lastUpdated": "Last updated on",
      "unkownLanguage": "Unkown language"
    }
  },
  "cs": {
    "controls": {
      "lastUpdated": "Naposledy upraveno",
      "unkownLanguage": "Neznámý jazyk"
    }
  }
}
</i18n>

<script>
export default {
  name: 'SubtitlesInfoBox',
  props: {
    subtitles: { type: Object, required: true },
  },
  computed: {
    formattedLastUpdatedAt() {
      return this.subtitles.created_at !== null ? new Date(this.subtitles.created_at).toLocaleString() : 'N/A';
    },
  },
};
</script>

<style scoped></style>
