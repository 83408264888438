<template>
  <div class="border-t box-content flex flex-row border-gray-300 bg-gray-200">
    <div class="border-r flex w-1/2 flex-row items-center space-x-3 border-gray-300 p-5">
      <SubtitlesInfoBox :subtitles="presentationSubtitlesData"></SubtitlesInfoBox>

      <div class="flex flex-1 flex-col justify-between self-start">
        <div class="space-x-3 space-y-3 space-y-reverse self-start">
          <span
            v-shortkey="['ctrl', 'alt', 'o']"
            class="relative inline-flex rounded-md shadow-sm"
            @click="$refs.file.click()"
            @shortkey="$refs.file.click()"
          >
            <button
              type="button"
              :class="importFail ? 'border-2 border-red-500' : 'border border-gray-300'"
              class="transition focus:outline-none inline-flex w-full items-center justify-center rounded-md bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
            >
              <svg
                class="mr-1 hidden h-4 w-4 xl:flex"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="whitespace-no-wrap">{{ $t('controls.importFile') }}</span>
            </button>

            <Tooltip
              style="left: 50%; bottom: 100%; transform: translateX(-50%)"
              class="whitespace-no-wrap mb-2 opacity-100"
              :text="$t('controls.wrongFileFormat')"
              :icon="`<svg class=&quot;inline-flex w-4 h-4 mr-1&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; viewBox=&quot;0 0 20 20&quot; fill=&quot;currentColor&quot;>
  <path fill-rule=&quot;evenodd&quot; d=&quot;M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z&quot; clip-rule=&quot;evenodd&quot; />
</svg>`"
              color="red-50"
              background="red-600"
              :class="{ hidden: !importFail }"
            ></Tooltip>
          </span>
          <span class="inline-flex rounded-md shadow-sm" @click="exportSubtitles">
            <button
              type="button"
              class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
            >
              <svg
                class="mr-1 hidden h-4 w-4 xl:flex"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="whitespace-no-wrap">{{ $t('controls.exportFile') }}</span>
            </button>
          </span>
        </div>

        <input ref="file" type="file" accept=".srt,.vtt" hidden @change="loadSubtitle" />
      </div>
    </div>

    <div class="flex w-1/2 flex-col items-start space-y-3 p-5">
      <div class="flex w-full flex-wrap space-x-3">
        <span
          v-shortkey="['alt', '+']"
          class="whitespace-no-wrap inline-flex rounded-md shadow-sm"
          @click="addSegmentToCurrentTime"
          @shortkey="addSegmentToCurrentTime()"
        >
          <button
            type="button"
            class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
          >
            <svg
              class="mr-1 hidden h-4 w-4 xl:flex"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              />
            </svg>
            <span>{{ $t('controls.addSegmentCurrentTime') }}</span>
          </button>
        </span>

        <span
          v-shortkey="['alt', ']']"
          class="whitespace-no-wrap inline-flex rounded-md shadow-sm"
          @click="scrollToSegment(firstActiveSubtitlesSegmentId)"
          @shortkey="scrollToSegment(firstActiveSubtitlesSegmentId)"
        >
          <button
            type="button"
            class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
          >
            <svg
              class="mr-1 hidden h-4 w-4 xl:flex"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.6949 16.7067C13.5074 16.8942 13.2531 16.9995 12.9879 16.9995C12.7228 16.9995 12.4685 16.8942 12.2809 16.7067L9.98792 14.4137L7.69492 16.7067C7.50632 16.8889 7.25372 16.9897 6.99152 16.9874C6.72933 16.9851 6.47851 16.88 6.2931 16.6945C6.1077 16.5091 6.00253 16.2583 6.00025 15.9961C5.99797 15.7339 6.09876 15.4813 6.28092 15.2927L9.28092 12.2927C9.46845 12.1053 9.72276 11.9999 9.98792 11.9999C10.2531 11.9999 10.5074 12.1053 10.6949 12.2927L13.6949 15.2927C13.8824 15.4803 13.9877 15.7346 13.9877 15.9997C13.9877 16.2649 13.8824 16.5192 13.6949 16.7067Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 7.98779C9.73481 7.98774 9.4805 7.88234 9.29301 7.69479L6.29301 4.69479C6.11085 4.50619 6.01005 4.25359 6.01233 3.99139C6.01461 3.72919 6.11978 3.47838 6.30519 3.29297C6.49059 3.10757 6.74141 3.0024 7.0036 3.00012C7.2658 2.99784 7.5184 3.09863 7.707 3.28079L10 5.57379L12.293 3.28079C12.4816 3.09863 12.7342 2.99784 12.9964 3.00012C13.2586 3.0024 13.5094 3.10757 13.6948 3.29297C13.8802 3.47838 13.9854 3.72919 13.9877 3.99139C13.99 4.25359 13.8892 4.50619 13.707 4.69479L10.707 7.69479C10.5195 7.88234 10.2652 7.98774 10 7.98779Z"
              />
            </svg>

            <span>{{ $t('controls.scrollToCurrentSegment') }}</span>
          </button>
        </span>
      </div>

      <div class="flex w-full flex-wrap space-x-3">
        <span class="inline-flex rounded-md shadow-sm">
          <span
            class="whitespace-no-wrap border inline-flex items-center rounded-l-md border-r-0 border-gray-300 bg-gray-50 px-2 text-xs text-gray-500"
          >
            {{ $t('controls.offset') }}
          </span>

          <input
            id="email"
            v-model="syncCustomValue"
            class="transition form-input z-0 block w-full rounded-none py-1 text-xs text-gray-800 duration-150 ease-in-out focus:z-10 focus:shadow-outline-red"
            :class="{
              'border-red-500 focus:border-red-500': syncCustomError,
              'focus:border-red-300': !syncCustomError,
            }"
            style="width: 6rem"
            placeholder="100"
            @keyup="syncCustomError = false"
            @keyup.enter="syncCustom"
          />

          <button
            class="transition border focus:outline-none relative inline-flex items-center border-l-0 border-gray-300 bg-gray-50 px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
            @click="syncCustomBack"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M2.5 2v6h6M2.66 15.57a10 10 0 1 0 .57-8.38" />
            </svg>
          </button>

          <button
            class="transition border focus:outline-none relative inline-flex items-center rounded-r-md border-l-0 border-gray-300 bg-gray-50 px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
            @click="syncCustom"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38" />
            </svg>
          </button>
        </span>

        <label for="showOnlyUnsaved" class="inline-flex cursor-pointer rounded-md shadow-sm">
          <span
            class="border inline-flex items-center rounded-l-md border-r-0 border-gray-300 bg-gray-50 px-2 py-1 text-xs"
          >
            <input
              id="showOnlyUnsaved"
              v-model="displayUnsavedOnly"
              type="checkbox"
              class="transition form-checkbox h-4 w-4 border-gray-400 text-red-600 duration-150 ease-in-out focus:border-red-300 focus:shadow-none"
              @change="setUnsavedFilter"
            />
          </span>

          <span
            class="whitespace-no-wrap border inline-flex items-center rounded-r-md border-l-0 border-gray-300 bg-gray-50 pr-2 text-xs font-medium text-gray-700 transition-colors duration-150 hover:text-gray-500"
          >
            {{ $t('controls.showOnlyUnsaved') }}
          </span>
        </label>

        <span class="inline-flex justify-self-end rounded-md shadow-sm" @click="openSettings">
          <button
            type="button"
            class="transition border focus:outline-none inline-flex w-full items-center justify-center rounded-md border-gray-300 bg-red-600 bg-white px-2 py-1 text-xs font-medium leading-5 text-white duration-150 ease-in-out hover:bg-red-500 focus:border-red-300 focus:shadow-outline-red"
          >
            <svg class="h-4 w-4 xl:mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="whitespace-no-wrap hidden xl:flex">{{ $t('controls.additionalSettings') }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "controls": {
      "newFile": "New file",
      "importFile": "Import file",
      "exportFile": "Export file",
      "selectFile": "Select file",
      "seeHistory": "See history",
      "offset": "Offset (ms)",
      "addSegmentCurrentTime": "Add segment to current time",
      "additionalSettings": "Additional settings",
      "scrollToCurrentSegment": "Scroll to current segment",
      "showOnlyUnsaved": "Show unsaved segments only",
      "wrongFileFormat": "Wrong file format"
    }
  },
  "cs": {
    "controls": {
      "newFile": "Nové tituky",
      "importFile": "Importovat",
      "exportFile": "Exportovat",
      "selectFile": "Vybrat tituky",
      "seeHistory": "Historie verzí",
      "offset": "Posunout segmenty o (ms)",
      "addSegmentCurrentTime": "Přidat nový segment na aktuální čas",
      "additionalSettings": "Nastavení",
      "scrollToCurrentSegment": "Vycentrovat aktuální segment",
      "showOnlyUnsaved": "Zobrazit pouze neuložené segmenty",
      "wrongFileFormat": "Nesprávný formát"
    }
  }
}
</i18n>

<script>
import SubtitlesInfoBox from './SubtitlesInfoBox.vue';

export default {
  name: 'SubtitlesControlHorizontal',
  components: {
    SubtitlesInfoBox,
  },
  props: {
    presentation: Object,
  },
  data() {
    return {
      syncCustomValue: '',
      syncCustomError: false,
      importFail: false,
      displayUnsavedOnly: false,
    };
  },
  computed: {
    presentationSubtitlesData() {
      return this.$store.state.presentationSubtitlesData
    },
    firstActiveSubtitlesSegmentId() {
      return this.$store.getters.firstActiveSubtitlesSegmentId;
    },
    videoPlayer() {
      return this.$store.getters.getVideo;
    },
    historyLength() {
      return this.$store.getters.getHistoryLength;
    },
  },
  mounted() {
    /*consol,e.log(this.$refs.control.clientHeight)*/
  },
  methods: {
    showHistory() {
      this.$store.dispatch('showHistory', true);
    },
    loadSubtitle(e) {
      this.importFail = false;
      const input = e.target;
      if ('files' in input && input.files.length > 0) {
        console.log(input.files[0]);
        if (input.files[0].type != 'text/vtt' && !input.files[0].name.includes('.vtt')) {
          if (input.files[0].name.includes('.srt')) {
            this.readFileContent(input.files[0])
              .then((content) => {
                this.$store.dispatch('loadSubtitle', content);
              })
              .catch((error) => console.log(error));
            return;
          }
          this.importFail = true;
          return;
        }
        this.readFileContent(input.files[0])
          .then((content) => {
            this.$store.dispatch('loadSubtitle', content);
          })
          .catch((error) => console.log(error));
      }
    },
    setUnsavedFilter() {
      this.$store.dispatch('setUnsavedFilter', this.displayUnsavedOnly);
    },
    readFileContent(file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    },
    syncCustom() {
      let ms = parseInt(this.syncCustomValue);
      if (isNaN(ms)) {
        this.syncCustomError = true;
        return;
      }
      this.syncBy(ms);
    },
    syncCustomBack() {
      let ms = parseInt('-' + this.syncCustomValue);
      if (isNaN(ms)) {
        this.syncCustomError = true;
        return;
      }
      this.syncBy(ms);
    },
    syncBy(ms) {
      this.$store.dispatch('resyncSubtitles', ms);
    },
    addSegmentToCurrentTime() {
      this.$store.dispatch('addSegmentToCurrentTime', this.videoPlayer.currentTime());
    },
    exportSubtitles() {
      let name = this.presentation.title.replaceAll(/\s/g, '_');
      let author = this.presentation.account.replaceAll(/\s/g, '_');

      this.$store.dispatch('exportSubtitles', `${name}-${author}`);
    },
    openSettings() {
      this.$store.dispatch('displaySettings', true);
    },
  },
};
</script>

<style scoped></style>
