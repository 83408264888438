<template>
  <div v-shortkey="['esc']" class="fixed inset-0 z-20 overflow-y-auto" @shortkey="escape">
    <div
      v-shortkey="['enter']"
      class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
      @shortkey="enter"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>&#8203;
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              v-if="modalData.type == 'error'"
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div
              v-else-if="modalData.type == 'success'"
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">
                {{ modalData.title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                  {{ modalData.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span
            v-if="modalData.buttonOk && modalData.buttonOk.display"
            class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
          >
            <button
              type="button"
              class="whitespace-no-wrap transition border focus:outline-none inline-flex w-full justify-center rounded-md border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @click="ok"
            >
              {{ modalData.buttonOk.text }}
            </button>
          </span>
          <span
            v-if="modalData.buttonCustom && modalData.buttonCustom.display"
            class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
          >
            <button
              type="button"
              class="whitespace-no-wrap transition border focus:outline-none inline-flex w-full justify-center rounded-md border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
              @click="custom"
            >
              {{ modalData.buttonCustom.text }}
            </button>
          </span>
          <span
            v-if="modalData.buttonCancel && modalData.buttonCancel.display"
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
          >
            <button
              type="button"
              class="whitespace-no-wrap transition border focus:outline-none inline-flex w-full justify-center rounded-md border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
              @click="cancel"
            >
              {{ modalData.buttonCancel.text }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  computed: {
    modalData() {
      return this.$store.getters.getModalData;
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch('displayModal', false);
      if (this.modalData.callback != null) {
        this.modalData.callback('cancel', this.modalData);
      }
    },
    ok() {
      this.$store.dispatch('displayModal', false);
      if (this.modalData.callback != null) {
        this.modalData.callback('ok', this.modalData);
      }
    },
    custom() {
      this.$store.dispatch('displayModal', false);
      if (this.modalData.callback != null) {
        this.modalData.callback('custom', this.modalData);
      }
    },
    escape() {
      this.$store.dispatch('displayModal', false);
      if (this.modalData.callback != null) {
        this.modalData.callback('escape', this.modalData);
      }
    },
    enter() {
      this.$store.dispatch('displayModal', false);
      if (this.modalData.callback != null) {
        this.modalData.callback('enter', this.modalData);
      }
    },
  },
};
</script>

<style scoped></style>
