<template>
  <div
    v-shortkey="['alt', 'z']"
    class="absolute inset-0 flex flex-col overflow-auto"
    style="min-width: 1024px"
    @shortkey="undo()"
  >
    <VideoInfo v-shortkey="['ctrl', 'space']" :presentation="presentation" @shortkey.native="togglePlayPause()" />

    <div v-shortkey="['alt', 'y']" class="flex flex-1 overflow-hidden" @shortkey="redo()">
      <div class="border-r flex w-1/2 flex-col overflow-auto border-gray-300">
        <div class="flex flex-1 flex-col bg-white p-5">
          <div id="embed-container">
            <div id="embed" class="box-border"></div>
          </div>

          <div class="mx-auto mt-5 flex flex-col items-stretch" style="width: 75%">
            <span
              class="transition border rounded-t-md border-b-0 border-gray-300 bg-white px-4 py-2 text-center text-base font-medium leading-5 text-gray-700 duration-150 ease-in-out"
            >
              {{ currentTime }}
            </span>

            <span class="z-0 flex items-stretch">
              <button
                type="button"
                class="flex-grow transition border focus:outline-none relative inline-flex items-center justify-center rounded-bl-md border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
                aria-label="Previous"
                @click="skipBy(-1000)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="none"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="11 19 2 12 11 5 11 19"></polygon>
                  <polygon points="22 19 13 12 22 5 22 19"></polygon>
                </svg>

                <span class="whitespace-no-wrap ml-1 text-xs">-1000ms</span>
              </button>

              <button
                type="button"
                class="flex-grow transition border focus:outline-none relative inline-flex items-center justify-center border-l-0 border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
                aria-label="Previous"
                @click="skipBy(-100)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  style="transform: scale(-1)"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="none"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>

                <span class="whitespace-no-wrap ml-1 text-xs">-100ms</span>
              </button>

              <button
                type="button"
                class="flex-grow transition border focus:outline-none relative inline-flex items-center justify-center border-l-0 border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-red-700 duration-150 ease-in-out hover:text-red-500 focus:z-10 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-red-700"
                aria-label="Previous"
                style="min-width: 64px"
                @click="togglePlayPause()"
              >
                <span class="mx-1">
                  <transition name="fade">
                    <svg
                      v-if="!isPlaying"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <polygon points="5 3 19 12 5 21 5 3"></polygon>
                    </svg>

                    <svg
                      v-if="isPlaying"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                    >
                      <path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" />
                    </svg>
                  </transition>
                </span>
              </button>

              <button
                type="button"
                class="flex-grow transition border focus:outline-none relative inline-flex items-center justify-center border-l-0 border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
                aria-label="Previous"
                @click="skipBy(100)"
              >
                <span class="whitespace-no-wrap mr-1 text-xs">+100ms</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="none"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              </button>

              <button
                type="button"
                class="flex-grow transition border focus:outline-none relative inline-flex items-center justify-center rounded-br-md border-l-0 border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-red-300 focus:shadow-outline-red active:bg-gray-100 active:text-gray-700"
                aria-label="Next"
                @click="skipBy(1000)"
              >
                <span class="whitespace-no-wrap mr-1 text-xs">+1000ms</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  style="transform: scale(-1)"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="none"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="11 19 2 12 11 5 11 19"></polygon>
                  <polygon points="22 19 13 12 22 5 22 19"></polygon>
                </svg>
              </button>
            </span>
          </div>

          <SubtitlesStaticSegment
            v-for="segment in activeSubtitlesSegments"
            :id="segment.id"
            :key="segment.id"
            mode="preview"
            class="mt-5"
            :subtitle="segment"
          />
        </div>
      </div>

      <div class="relative w-1/2">
        <TimelineVertical :presentation="presentation"></TimelineVertical>
      </div>
    </div>

    <SubtitlesControlHorizontal ref="control" :presentation="presentation"></SubtitlesControlHorizontal>

    <transition name="fade">
      <SaveModal
        v-if="$store.state.showSaveConfirmModal"
        :key="$store.state.showSaveConfirmModal"
        :presentation="presentation"
      ></SaveModal>
    </transition>
    <transition name="fade">
      <SettingsModal v-if="$store.state.showSettings"></SettingsModal>
    </transition>
    <transition name="fade">
      <HistoryModal v-if="$store.state.showHistory"></HistoryModal>
    </transition>
    <transition name="fade">
      <WarningsModal v-if="$store.state.showWarnings"></WarningsModal>
    </transition>
  </div>
</template>

<i18n>
{
  "en": {
    "withoutSaving": {
      "title": "Leaving without saving",
      "text": "Are you sure that you that really want to leave without saving?",
      "yes": "Yes",
      "no": "No"
    }
  },
  "cs": {
    "withoutSaving": {
      "title": "Změny nejsou uloženy",
      "text": "Opravdu se chcete vrátit bez uložení nových změn?",
      "yes": "Ano",
      "no": "Ne"
    }
  }
}
</i18n>

<script>
import HistoryModal from '@/components/HistoryModal';
import SaveModal from '@/components/SaveModal';
import SettingsModal from '@/components/SettingsModal';
import SubtitlesControlHorizontal from '@/components/SubtitlesControlHorizontal';
import SubtitlesStaticSegment from '@/components/SubtitlesStaticSegment';
import TimelineVertical from '@/components/TimelineVertical';
import VideoInfo from '@/components/VideoInfoHorizontal';
import WarningsModal from '@/components/WarningsModal';

export default {
  name: 'Editor',
  components: {
    VideoInfo,
    TimelineVertical,
    SubtitlesControlHorizontal,
    SubtitlesStaticSegment,
    SaveModal,
    SettingsModal,
    HistoryModal,
    WarningsModal,
  },
  data() {
    return {
      leavingTo: '',
      leavingConfirmed: false,
      currentTime: '00:00:00:0',
      isPlaying: false,
    };
  },
  computed: {
    presentationId() {
      return this.$store.state.presentationId;
    },
    presentation() {
      return this.$store.state.editorData;
    },
    activeSubtitlesSegments() {
      return this.$store.getters.activeSubtitlesSegments;
    },
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      if (this.$route.name && this.$route.name == 'Editor' && this.$store.getters.isEditing) {
        this.$store.dispatch('resetEditing');
        event.preventDefault();
        event.returnValue = '';
      }
    });

    this.loadSlidesLiveEmbed(this.presentationId);

    this.$store.dispatch('initSettings');
  },
  methods: {
    undo() {
      this.$store.dispatch('undo');
    },
    redo() {
      this.$store.dispatch('redo');
    },
    leavingDialogClosed(exitType) {
      if (exitType == 'ok') {
        this.leavingConfirmed = true;
        this.$store.dispatch('resetEditing');
        this.$router.push(this.leavingTo);
      } else if (exitType == 'cancel') {
        this.leavingConfirmed = false;
        this.leavingTo = '';
      }
    },
    skipBy(ms) {
      this.$store.dispatch('skipBy', ms);
    },
    togglePlayPause() {
      if (this.$store.getters.getVideo.isPlaying()) {
        this.$store.getters.getVideo.pause();
      } else {
        this.$store.getters.getVideo.play();
      }
    },
    loadSlidesLiveEmbed(presentationId) {
      let embed = new SlidesLiveEmbed('embed', {
        presentationId: presentationId,
        autoPlay: false,
        verticalEnabled: false,
        hideTitle: true,
        zoomRatio: 0.7,
        zoomSliderColor: '#6B7281',
        zoomHandleColor: '#D2D6DC',
        onLoad: () => {
          setInterval(() => {
            let ms = embed.currentTime();
            this.isPlaying = embed.isPlaying();

            let milliseconds = Math.round(parseInt(ms % 1000)),
              seconds = Math.floor((ms / 1000) % 60),
              minutes = Math.floor((ms / (1000 * 60)) % 60),
              hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            milliseconds = Math.round(milliseconds / 100);

            this.currentTime =
              hours +
              ':' +
              minutes +
              ':' +
              seconds +
              ':' +
              milliseconds; /* (milliseconds > 950 ? seconds + 1 : seconds) */

            this.$store.dispatch('setCurrentTime', ms);
          }, 10);
        },
      });

      this.$store.dispatch('setVideo', embed);
    },
  },
};
</script>

<style scoped></style>
