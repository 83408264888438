<template>
  <div v-shortkey="['esc']" class="fixed inset-0 z-20 overflow-y-auto" @shortkey="close">
    <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>&#8203;
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">
                {{ $t('history') }}
              </h3>
              <hr class="mb-4 mt-2" />
              <div class="mt-2 text-sm">
                <div
                  v-for="(historyItem, index) in history.slice().reverse()"
                  :key="historyItem.id"
                  class="truncate group flex w-full items-center justify-between py-1 hover:text-gray-700"
                  :class="{
                    'font-bold text-gray-700':
                      (currentHistoryStep == null && index == 0) || history.length - index == currentHistoryStep,
                    'border-t mt-2 border-gray-300 pt-3': index !== 0,
                  }"
                >
                  <div>
                    {{ historyItem.date.toLocaleString() }} -
                    <span v-if="historyItem.type === 'remove'">{{ $t('removeSegment') }}</span>
                    <span v-else-if="historyItem.type === 'create'"
                      >{{ $t('createSegment') }} {{ convertTime(historyItem.data.time) }}</span
                    >
                    <span v-else-if="historyItem.type === 'merge'">{{ $t('mergeSegment') }}</span>
                    <span v-else-if="historyItem.type === 'update'">{{ $t('updateSegment') }}</span>
                    <span v-else-if="historyItem.type === 'resync'"
                      >{{ $t('resyncSegment') }}({{ historyItem.data.ms }} ms)</span
                    >

                    <span class="rounded mx-1 inline items-center bg-red-300 text-xs font-semibold text-red-900">
                      {{ '#' + subtitlesSegments.findIndex((segment) => segment.uid === historyItem.data.new.uid) }}
                    </span>

                    <span class="invisible">.</span>

                    <!--<span class="text-xs font-medium text-gray-600">
                  {{ `"${historyItem.data.old.text}"` }}
                    </span>-->
                  </div>

                  <div class="invisible group-hover:visible">
                    <span
                      class="whitespace-no-wrap rounded-md shadow-sm xl:mr-2"
                      @click="goToSegment(historyItem.data.new.uid)"
                    >
                      <button
                        type="button"
                        class="transition border focus:outline-none rounded-md border-gray-300 bg-gray-300 px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-900 focus:border-red-300 focus:shadow-outline-red active:bg-gray-200 active:text-black"
                      >
                        <span>{{ $t('goToSegment') }}</span>
                      </button>
                    </span>
                    <span class="whitespace-no-wrap rounded-md shadow-sm xl:mr-2" @click="goToHistory(index)">
                      <button
                        type="button"
                        class="transition border focus:outline-none rounded-md border-gray-300 bg-gray-300 px-2 py-1 text-xs font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-900 focus:border-red-300 focus:shadow-outline-red active:bg-gray-200 active:text-black"
                      >
                        <span>{{ $t('rollBack') }}</span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              v-shortkey="['esc']"
              type="button"
              class="transition border focus:outline-none inline-flex w-full justify-center rounded-md border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
              @shortkey="close"
              @click="close"
            >
              {{ $t('close') }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"history": "History",
"close": "Close",
"removeSegment": "Removed segment",
"createSegment": "Created segment at",
"mergeSegment": "Merged segment",
"updateSegment": "Updated segment",
"resyncSegment": "Resynced segments",
"goToSegment": "Go to the segment",
"rollBack": "Roll back to this event"
},
"cs": {
"history": "Historie",
"close": "Zavřít",
"removeSegment": "Odstranění segmentu",
"createSegment": "Vytvoření segmentu v čase",
"mergeSegment": "Spojení dvou segmentů",
"updateSegment": "Změna segmentu",
"resyncSegment": "Synchronizace segmentů",
"goToSegment": "Přejít na segment",
"rollBack": "Přejít zpět k této verzi"
}
}

</i18n>

<script>
export default {
  name: 'HistoryModal',
  computed: {
    history() {
      return this.$store.getters.getHistory;
    },
    currentHistoryStep() {
      return this.$store.getters.getCurrentHistoryStep;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('showHistory', false);
    },
    goToHistory(historySteps) {
      historySteps =
        this.history.length -
        1 -
        historySteps -
        (this.currentHistoryStep == null ? this.history.length - 1 : this.currentHistoryStep - 1);
      this.$store.dispatch('goToHistory', historySteps);
      this.$store.dispatch('showHistory', false);
      this.$store.dispatch('setSelectedSubtitlesSegmentId');
    },
    goToSegment(uid) {
      this.close();
      setTimeout(() => {
        this.scrollToSegment(uid, true);
        setTimeout(() => {
          this.$store.dispatch('setSelectedSubtitlesSegmentId', { uid: uid });
        }, 250);
      }, 250);
    },
    convertTime(ms) {
      let milliseconds = parseInt(ms % 1000),
        seconds = Math.floor((ms / 1000) % 60),
        minutes = Math.floor((ms / (1000 * 60)) % 60),
        hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds.toString().padStart(3, '0');
    },
  },
};
</script>

<style scoped></style>
