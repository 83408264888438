<template>
  <div class="border-b flex flex-col border-gray-300 bg-gray-600">
    <div class="flex flex-row">
      <div class="border-r align-center justify flex space-x-3 border-gray-500 p-5 lg:w-1/2">
        <div
          class="h-16 w-16 rounded-full bg-cover bg-center"
          :style="'background-image: url(\'' + presentation.thumbnail_url + '\')'"
        ></div>
        <div class="self-center">
          <h2 class="text-lg font-medium text-gray-50">
            {{ presentation.title }}
            <span class="text-sm font-medium text-gray-200"
              >(<a target="_blank" :href="'https://slideslive.com/' + presentation.presentation_id">{{
                presentation.presentation_id
              }}</a
              >)</span
            >
          </h2>
          <h3 class="text-sm font-medium text-gray-200">{{ $t('by') }} {{ presentation.account }}</h3>
        </div>
      </div>

      <div class="flex-grow flex flex-row items-center justify-between space-x-3 p-5 lg:w-1/2">
        <div class="flex flex-row space-x-3">
          <span class="inline-flex rounded-md">
            <button
              type="button"
              :class="{ 'cursor-not-allowed opacity-50': historyLength == 0 || currentHistoryStep == 0 }"
              class="transition focus:outline-none inline-flex w-full items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
              @click="undo()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 hidden h-5 w-5 xl:flex"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                />
              </svg>
              <span class="whitespace-no-wrap">{{ $t('controls.undo') }}</span>
            </button>
          </span>

          <span class="inline-flex rounded-md">
            <button
              type="button"
              :class="{ 'cursor-not-allowed opacity-50': currentHistoryStep == null }"
              class="transition focus:outline-none inline-flex w-full items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 duration-150 ease-in-out hover:text-gray-500 focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 active:text-gray-800"
              @click="redo()"
            >
              <span class="whitespace-no-wrap">{{ $t('controls.redo') }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="ml-2 hidden h-5 w-5 transform xl:flex"
                style="transform: scaleX(-1)"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                />
              </svg>
            </button>
          </span>
        </div>

        <transition name="fade">
          <div
            v-if="Object.keys(warnings).length > 0"
            class="relative"
            @mouseenter="showDropdownWarning = true"
            @mouseleave="showDropdownWarning = false"
          >
            <span class="block cursor-pointer rounded-full bg-orange-500 p-1" @click="$store.state.showWarnings = true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div
              :class="
                showDropdownWarning && Object.keys(warnings).length > 0
                  ? 'visible scale-100 opacity-100'
                  : 'invisible scale-95 opacity-0'
              "
              :style="
                showDropdownWarning && Object.keys(warnings).length > 0
                  ? 'transition: opacity ease-out 150ms, transform ease-out 150ms, visibility 0ms'
                  : 'transition: opacity ease-out 150ms, transform ease-out 150ms, visibility 0ms 150ms'
              "
              style="--transform-translate-x: -50%; left: 50%"
              class="absolute inset-x-0 z-10 w-56 transform rounded-md pt-2 shadow-lg"
            >
              <div class="rounded-md bg-white shadow-xs">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <div
                    v-for="warning in warnings"
                    class="focus:outline-none block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    @click="goToSegmentWarning(warning[0].uid)"
                  >
                    <span class="capitalize">{{ warning[0].type }}</span>
                    <span class="rounded mx-1 inline items-center bg-red-300 text-xs font-semibold text-red-900">
                      {{ '#' + subtitlesSegments.findIndex((segment) => segment.uid === warning[0].uid) }}
                    </span>
                    <span class="invisible">.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div class="tooltip-wrapper inline-flex">
          <span
            class="rounded-md"
            @click="
              () => {
                if (unsavedChanges) saveConfirm();
              }
            "
          >
            <button
              type="button"
              :class="{ 'cursor-not-allowed opacity-50': !unsavedChanges }"
              class="transition focus:outline-none inline-flex w-full items-center justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-medium leading-5 text-white duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 hidden h-5 w-5 xl:flex"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z"
                />
              </svg>
              <span class="whitespace-no-wrap">{{ $t('controls.saveChanges') }}</span>
            </button>
          </span>

          <Tooltip
            v-if="!unsavedChanges"
            style="left: 50%; top: 100%; transform: translateX(-50%)"
            class="whitespace-no-wrap mt-2"
            :text="$t('controls.noChanges')"
            color="gray-800"
            background="white"
          ></Tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "by": "by",
    "controls": {
      "undo": "Undo",
      "redo": "Redo",
      "goBack": "Go back",
      "saveChanges": "Save changes",
      "noChanges": "No changes to save"
    }
  },
  "cs": {
    "by": "od",
    "controls": {
      "undo": "Krok zpět",
      "redo": "Krok vpřed",
      "goBack": "Zpět",
      "saveChanges": "Uložit změny",
      "noChanges": "Žádné změny nebyly provedeny"
    }
  }
}
</i18n>

<script>
export default {
  name: 'VideoInfo',
  props: {
    presentation: Object,
  },
  data() {
    return {
      showDropdownWarning: false,
    };
  },
  computed: {
    unsavedChanges() {
      return this.$store.state.editing;
    },
    subtitlesSegments() {
      return this.$store.getters.subtitlesSegments;
    },
    historyLength() {
      return this.$store.getters.getHistoryLength;
    },
    currentHistoryStep() {
      return this.$store.getters.getCurrentHistoryStep;
    },
    warnings() {
      return this.$store.getters.getWarnings;
    },
  },
  watch: {
    '$store.state.warning'() {
      console.log('length', this.$store.getters.getWarnings.length);

      return this.$store.getters.getWarnings;
    },
  },
  methods: {
    saveConfirm() {
      this.$store.dispatch('setSaveModal', true);
    },
    redo() {
      if (this.currentHistoryStep == null) return;
      this.$store.dispatch('redo');
    },
    undo() {
      if (this.historyLength == 0 || this.currentHistoryStep == 0) return;
      this.$store.dispatch('undo');
    },
    goToSegmentWarning(uid) {
      setTimeout(() => {
        this.scrollToSegment(uid, true);

        setTimeout(() => {
          this.$store.dispatch('setSelectedSubtitlesSegmentId', { uid: uid });
        }, 250);
      }, 250);
    },
  },
};
</script>

<style scoped></style>
