<template>
  <div>
    <div class="border-b flex flex-col border-gray-300 bg-gray-600">
      <div class="border-r align-center justify flex space-x-3 border-gray-500 p-5">
        <template v-if="presentation">
          <div
            class="h-16 w-16 rounded-full bg-cover bg-center"
            :style="'background-image: url(\'' + presentation.thumbnail_url + '\')'"
          ></div>
          <div class="self-center">
            <h2 class="text-lg font-medium text-gray-50">
              {{ presentation.title }}
              <span class="text-sm font-medium text-gray-200"
                >(<a target="_blank" :href="'https://slideslive.com/' + presentation.presentation_id">{{
                  presentation.presentation_id
                }}</a
                >)</span
              >
            </h2>
            <h3 class="text-sm font-medium text-gray-200">{{ $t('by') }} {{ presentation.account }}</h3>
          </div>
        </template>
        <template v-else>
          <div class="self-center">
            <h2 class="text-lg font-medium text-gray-50">Loading presentation&hellip;</h2>
          </div>
        </template>
      </div>
    </div>
    <template v-if="loadingErrors && loadingErrors.length > 0">
      <div class="m-5 space-y-5">
        <div v-for="error in loadingErrors" :key="error">{{ error }}</div>
      </div>
    </template>
    <template v-if="editorState === 'select-subtitles'">
      <div class="m-5 space-y-5">
        <template v-if="availablePresentationSubtitles && availablePresentationSubtitles.length > 0">
          <div>Select subtitles you want to edit.</div>

          <div class="flex flex-col space-y-5">
            <SubtitlesInfoBox
              v-for="subtitlesInfo in availablePresentationSubtitles"
              :key="subtitlesInfo.id"
              class="cursor-pointer"
              :subtitles="subtitlesInfo"
              @click="$emit('subtitles-selected', subtitlesInfo.id)"
            />
          </div>
        </template>
        <template v-else>
          <div>
            No subtitles were found for this presentation. If you want to create a new one upload them on
            <a
              class="underline hover:no-underline"
              :href="`https://slideslive.com/${presentationId}/-/edit?tab=advanced`"
              >SlidesLive Presentation edit page</a
            >.
          </div>
        </template>
      </div>
    </template>
    <template v-if="editorState === 'loading-subtitles'">
      <div class="m-5 space-y-5">
        <div>Loading subtitles&hellip;</div>
      </div>
    </template>
  </div>
</template>

<script>
import SubtitlesInfoBox from '@/components/SubtitlesInfoBox.vue';

export default {
  name: 'LoadingView',
  components: {
    SubtitlesInfoBox,
  },
  props: {
    editorState: { type: String, required: true },
    loadingErrors: { type: Array, required: true },
  },
  computed: {
    presentationId() {
      return this.$store.state.presentationId;
    },

    presentation() {
      return this.$store.state.editorData;
    },

    availablePresentationSubtitles() {
      return this.$store.state.editorData.available_subtitles;
    },
  },
};
</script>
